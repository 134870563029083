<template>
  <div>
    <function-entry></function-entry>
    <b-row>
      <b-col cols="2">
        <ul class="pl-2"
            style="color: #aaa; list-style-type: none; display: flex; gap: 10px; flex-direction: column;padding-right: 20px ">
          <li @click="navCurrent = item.title" v-for="(item, index) of category" :key="index" :style="[
              {
                padding: item.title === navCurrent ?  '8px 5px 8px 15px':'8px 5px 8px 5px',
                cursor: 'pointer',
                background: item.title === navCurrent ? '#4190f7' : '',
                color: item.title === navCurrent ? 'white' : '',
                borderRadius: item.title === navCurrent ? '5px' : '',
                boxShadow: item.title === navCurrent ? '0 4px 18px -4px rgba(24, 144, 255, 0.65)' : '',
                transition: '.2s all ease'
              },
          ]">{{ item.title }}
          </li>
        </ul>
      </b-col>
      <b-col cols="10">
        <p>
          {{ navCurrent }}
        </p>
        <b-card @click="showDetails(item)" class="mouse-enter-shadow" v-for="(item,index) of dataList" :key="index">
          <div class="d-flex justify-content-between">
            <p class="text-bold" style="font-weight: bold; font-size: 15px">
              {{ item.function_name }}<span style="opacity: .4; font-size: 12px"> - {{ item.create_date }}</span></p>
            <div>
              <b-badge>损失类型:人身伤害(电梯事故)</b-badge>
              <b-badge style="margin-left: 8px;" v-if="item.fault_level == 1">重要程度:低</b-badge>
              <b-badge style="margin-left: 8px;" v-else-if="item.fault_level == 2">重要程度:中</b-badge>
              <b-badge variant="danger" style="margin-left: 8px;" v-else-if="item.fault_level == 3">重要程度:高
              </b-badge>
            </div>
          </div>
          <p style="color: #ababab; font-size: 13px">
            {{ item.fault_name }}，可能由{{ item.fault_reason }}导致，推荐解决措施{{ item.fault_solution }}。</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="6" sm="6">
        <!--								<span class="text-muted">-->
        <!--									{{-->
        <!--                    `-->
        <!--                                    ${$t("Show")}-->
        <!--                                    ${dataMeta.from}-->
        <!--                                    ${$t("to")}-->
        <!--                                    ${dataMeta.to}-->
        <!--                                    ${$t("of")}-->
        <!--                                    ${total}-->
        <!--                                    ${$t("Entries")}-->
        <!--                                    `-->
        <!--                  }}-->
        <!--								</span>-->
      </b-col>
      <!-- Pagination -->
      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="6" sm="6">
        <b-pagination v-model="dataMeta.page" :per-page="dataMeta.pagesize" :total-rows="dataMeta.total"
                      hide-goto-end-buttons
                      size="sm"></b-pagination>
      </b-col>
    </b-row>

    <b-modal v-model="detailModal.show" title="故障详情" size="lg" ok-only ok-title="查看诊断详情"
             @ok="ToAgentDetail(detailModal.data)">
      <b-row>
        <b-col cols="12">
          <b-card title="基本信息">
            <div>时间： {{ detailModal.data.create_date }}</div>
            <div>
              故障名称：
              {{ detailModal.data.fault_name }}（ {{ detailModal.data.id }} ）
            </div>
            <div>
              函数名称（函数ID）:
              {{ detailModal.data.function_name }}（ {{ detailModal.data.function_id }} ）
            </div>
            <div>报警类型： {{ detailModal.data.type }}</div>
            <div>报警等级： {{ detailModal.data.degree }}</div>
          </b-card>
        </b-col>
        <!--              <b-col cols="6">-->
        <!--                <b-card title="问题描述">-->
        <!--                  <b-card-text style="white-space: pre-line"-->
        <!--                               v-html="detailModal.data.DescDetail ? detailModal.data.DescDetail.replaceAll('，', '<br/>').replaceAll(',', '<br/>') : '暂无数据'"></b-card-text>-->
        <!--                </b-card>-->
        <!--              </b-col>-->
      </b-row>
      <b-card title="问题数据">
        <p v-show="!detailModal.data.data">暂无数据</p>
        <div v-show="detailModal.data.data" id="chart_agentdetail" style="height: 220px">
        </div>
      </b-card>

      <b-card title="自动诊断">
        <b-card-text style="white-space: pre-line"
                     v-html="detailModal.data.fault_reason ? detailModal.data.fault_reason : '暂无数据'"></b-card-text>
      </b-card>

      <b-card title="推荐措施">
        <b-card-text style="white-space: pre-line"
                     v-html="detailModal.data.fault_solution ? detailModal.data.fault_solution : '暂无数据'"></b-card-text>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import FunctionEntry from "@/views/front/cabr/diagnosis/dashboard/components/FunctionEntry.vue";
import VerticalMenu from "@/views/layout/app-sidebar-menu/components/VerticalMenu.vue";
import {general_list} from "@/api/diagnosis";
import {Line} from "@antv/g2plot";
import router from "@/router";

export default {
  name: 'GeneralIndex',
  data: () => ({
    navCurrent: '',
    detailModal: {
      show: false,
      data: {},
    },
    dataList: [],
    dataMeta: {
      pagesize: 20,
      page: 1,
      total: 0
    },
    category: [],
    navList: [
      {
        title: '环境评价',
      },
      {
        title: '暖通空调',
      },
      {
        title: '给排水',
      },
      {
        title: '电气',
      },
      {
        title: '能源管理',
      },
    ]
  }),
  components: {VerticalMenu, FunctionEntry},
  mounted() {
    this.doGet(true)
  },
  methods: {

    showDetails(detail) {
      this.detailModal.show = true;
      this.detailModal.data = detail;
      const data = JSON.parse(detail.data);
      // let chartData = [];
      // data.days.map((timeItem, timeIndex) => {
      //   chartData.push({
      //     time: timeItem,
      //     value: data.data[0][timeIndex],
      //     agent: data.legend[0],
      //   });
      // });
      const chartData = []
      for (let i in data.time) {
        const d = new Date(data.time[i])
        chartData.push({
          time: `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1}-${d.getDate() + 1 < 10 ? '0' + (d.getDate() + 1) : d.getDate() + 1} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
          pvvarname: data.pvvarname[i],
          value: data.value[i],
        })
      }
      this.detailModal.data.chartData = chartData
      setTimeout(() => {
        const line = new Line("chart_agentdetail", {
          data: chartData,
          padding: "auto",
          xField: "time",
          yField: "value",
          seriesField: 'pvvarname',
          xAxis: {
            tickCount: 5,
          },
          yAxis: {
            nice: true,
            grid: {
              line: {
                style: {
                  stroke: "#82868b",
                  lineWidth: 1,
                  lineDash: [2, 8],
                  strokeOpacity: 0.7,
                  shadowColor: "#82868b",
                  shadowBlur: 10,
                  shadowOffsetX: 5,
                  shadowOffsetY: 5,
                  cursor: "pointer",
                },
              },
            },
          },
          slider: {
            start: 0,
            end: 1,
            textStyle: {
              fill: "#eeeeee",
              fillOpacity: 1,
              shadowColor: "black",
              shadowBlur: 1,
            },
            trendCfg: {
              lineStyle: {
                fillOpacity: 1,
              },
            },
          },
        });
        line.render();
      }, 1000);
    },
    ToAgentDetail(detail) {
      router.push({
        name: "AgentDetail", params: {id: detail.id, detail}
      });
    },
    doGet(isInit = false) {
      general_list({
        page: this.dataMeta.page,
        pagesize: this.dataMeta.pagesize,
        system: this.navCurrent
      }).then(res => {
        this.dataList = res.data.data
        // this.dataMeta.page = res.data.meta.page
        this.dataMeta.pagesize = res.data.meta.pagesize
        this.dataMeta.total = res.data.meta.total
        this.category = res.data.category
        // this.navCurrent = res.data.navCurrent
        if (isInit) {
          this.navCurrent = this.category[0]['title']
        }
      })
    }
  },
  watch: {
    'dataMeta.page'() {
      this.doGet()
    },
    'navCurrent'() {
      this.doGet()
    },
  }
}
</script>
<style scoped>
.mouse-enter-shadow:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  transition: .2s all ease-in-out;
  cursor: pointer;
}

</style>
